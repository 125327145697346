<template>
  <!-- eslint-disable -->

  <section id="content-wrapper">
    <h1 class="content-title" id="main-title">Bem-vindo!</h1>
    <v-carousel
      style="height: unset !important; position: relative"
      class="main-page"
      hide-delimiters
      cycle
      interval="4000"
      progress
      progress-color="hsl(190, 73%, 45%)"
      :show-arrows="false"
    >
      <v-carousel-item
        style="height:100%"
        v-for="page in pages"
        :key="page.titulo"
        :src="page.background"
      >
        <h1 id="main-title">{{ page.titulo }}</h1>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>
<script>
/* eslint-disable */
export default {
  name: 'Inicio',
  metaInfo: {
    title: 'A Divisão da Cor',
    titleTemplate: null,
    meta: [
      {
        name: 'description',
        content:
          'A Divisão da Cor apresenta soluções de pré-impressão, design gráfico, CTP, impressão digital e packaging personalizado.',
      },
      {
        name: 'keywords',
        content:
          'Consultoria Gráfica, Design Gráfico, Gráfica Braga, Artes Gráficas',
      },
    ],
  },
  data() {
    return {
      pages: [
        {
          background: require('../assets/DSC07884.jpg'),
        },
        {
          background: require('../assets/DSC07869.jpg'),
        },
        {
          background: require('../assets/DSC07881.jpg'),
        },
        {
          background: require('../assets/DSC07889.jpg'),
        },
        {
          background: require('../assets/DSC07892.jpg'),
        },
      ],
    };
  },
};
</script>
<style scoped>
.content-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  color: var(--clr-white);
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
#content-wrapper {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 450px;
  flex: 1 0 450px;
  display: flex;
  align-content: stretch;
  animation: fadeIn ease 1.5s;
}
#main-title {
  margin: 0 auto;
  font-size: 3rem;
}

@media screen and (max-width: 1264px) {
  #main-title {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 600px) {
  #main-title {
    font-size: 1.6rem;
  }
}
</style>
