<template>
  <!-- eslint-disable -->
  <v-app>
    <Navbar />
    <router-view></router-view>
    <div
      v-if="this.$router.currentRoute.name === 'home'"
      id="shorter-footer"
    ></div>
    <div v-else id="footer"></div>
  </v-app>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue';
export default {
  name: 'App',
  metaInfo: {
    title: 'A Divisão da Cor',
    titleTemplate: 'A Divisão da Cor | %s',
  },
  components: {
    Navbar,
    // Footer,
  },
  data: () => ({
    //
  }),
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
:root {
  --clr-grey-800: hsl(0, 0%, 24%);
  --clr-grey-200: hsl(235, 4%, 50%);
  --clr-white: rgb(248, 249, 252);
  --clr-accent: hsl(190, 73%, 45%);
  --clr-accent-light: hsl(190, 73%, 79%);
  --clr-accent-dark: hsl(190, 73%, 33%);
  --clr-green-700: hsl(137, 6%, 21%);
  --clr-red-400: hsl(355, 78%, 41%);
}
#app {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--clr-white) !important;
  display: flex;
  flex-flow: column;
  height: 100%;
}
html {
  overflow: scroll !important;
  overflow-x: hidden !important;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
#footer {
  flex: 0 1 100px;
  margin-top: 0;
}
#shorter-footer {
  flex: 0 1 96px;
  margin-top: 0;
}
section#content-wrapper
  div.v-window.v-item-group.theme--dark.v-carousel
  div.v-window__container
  div.v-window-item.portraits
  div.v-image.v-responsive.v-carousel__item.theme--dark
  div.v-image__image.v-image__image--cover {
  z-index: 1 !important;
}
.row {
  margin: 0 !important;
}
.v-application a {
  color: var(--clr-grey-800) !important;
  text-decoration: none !important;
}
.theme--light.v-app-bar.v-toolbar.v-sheet,
.theme--light.v-card {
  background-color: var(--clr-white) !important;
}

.v-toolbar__content,
.v-toolbar {
  height: 100% !important;
}
#header-wrapper
  > header.hidden-sm-and-down.v-sheet.theme--light.v-toolbar.v-toolbar--flat.v-app-bar {
  height: 200px !important;
}
#header-wrapper
  > header.hidden-md-and-up.v-sheet.theme--light.v-toolbar.v-toolbar--flat.v-app-bar {
  height: 100px !important;
}
.v-tabs-bar {
  font-weight: 500;
  background-color: var(--clr-white) !important;
}
.v-tabs-slider-wrapper {
  display: none;
}
/* cor texto tabs, texto menu mobile, arrows fechadas menu mobile */
.v-tab--active,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled,
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.theme--light.v-icon {
  color: var(--clr-grey-800) !important;
}
.v-tab.v-tab--active.nav-tab-lg:focus {
  text-shadow: 0 0 0.5px var(--clr-accent), 0 0 0.5px var(--clr-accent);
}
/* cor texto menu desktop */
.theme--light.v-list-item.desktop-list:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--clr-grey-300) !important;
}
/* cor texto menu mobile aberto, arrows abertas menu mobile*/
.v-list-group--active > .v-list-group__header .v-list-item,
.v-list-group--active > .v-list-group__header .v-list-item__content,
.v-list-group--active
  > .v-list-group__header
  .v-list-group__header__prepend-icon
  .v-icon,
.v-list .v-list-item--active .v-icon {
  color: var(--clr-accent) !important;
}
/* spacing menu mobile sublista */
.v-list-group__items {
  margin-top: -5px;
}
/* font menu mobile */
.v-list-item__title#mobile-list {
  font-size: 2.125rem !important;
  text-align: center;
  font-weight: 500;
}
/* font menu mobile sublista */
.v-list-item__title#mobile-list-small {
  font-size: 1.3rem !important;
  text-align: center;
}
/* tamanho arrows menu mobile */
.v-icon.v-icon {
  font-size: 50px !important;
}
/* icons default tamanho */
.v-btn--icon.v-size--small .v-icon,
.v-btn--fab.v-size--small .v-icon {
  font-size: 24px !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).active {
  color: var(--clr-accent) !important;
  background-color: var(--clr-white) !important;
  fill: var(--clr-accent) !important;
}
.theme--light.v-tabs .v-tab:hover::before,
.theme--light.v-tabs .v-tab--active:focus::before,
.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0 !important;
}
.theme--light.v-list-item:hover::before {
  -webkit-box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 0.3 !important;
}
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding: 0 !important;
}
.v-ripple__container {
  background: var(--clr-accent) !important;
  color: var(--clr-accent) !important;
  opacity: 0.5 !important;
}
.v-list-item--link::before {
  background: var(--clr-accent) !important;
}
.v-list-item__title {
  -webkit-transition: text-shadow 0.3s;
  -o-transition: text-shadow 0.3s;
  transition: text-shadow 0.3s;
}
.theme--light.v-list-item:hover .v-list-item__title:not(#mobile-list),
.v-list-item__title:hover:not(#mobile-list) {
  text-shadow: 0 0 0.5px rgb(98, 98, 71), 0 0 0.5px rgb(98, 98, 71);
  color: var(--clr-grey-800) !important;
}
.v-menu__content {
  -webkit-box-shadow: 0px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 0 0 16px 16px !important;
  min-width: 200px !important;
}
.v-tooltip__content {
  border-radius: 10px !important;
}
.v-btn--icon.v-size--x-large .v-icon,
.v-btn--fab.v-size--x-large .v-icon {
  font-size: 0.7em !important;
}
.theme--light.v-btn:hover::before {
  opacity: 0.02 !important;
}
.theme--light.v-btn:focus::before {
  opacity: 0 !important;
}
.theme--light.v-list-item:focus::before {
  opacity: 0 !important;
}
.theme--light.v-list .v-list-group--active::before,
.theme--light.v-list .v-list-group--active {
  border-radius: 16px !important;
  background: rgb(246, 246, 246);
  -webkit-box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.15);
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.v-list-item__title {
  font-size: 1.2rem !important;
}
.v-list--rounded .v-list-item,
.v-list--rounded .v-list-item::before,
.v-list--rounded .v-list-item > .v-ripple__container,
.v-list--rounded.v-list--two-line .v-list-item,
.v-list--rounded.v-list--two-line .v-list-item::before,
.v-list--rounded.v-list--two-line .v-list-item > .v-ripple__container {
  border-radius: 10px !important;
}
/* slides */
.v-window-x-transition-enter-active,
.v-window-x-transition-leave-active,
.v-window-x-reverse-transition-enter-active,
.v-window-x-reverse-transition-leave-active,
.v-window-y-transition-enter-active,
.v-window-y-transition-leave-active,
.v-window-y-reverse-transition-enter-active,
.v-window-y-reverse-transition-leave-active {
  transition: 1s ease !important;
}
.slide-bottom-button {
  background: rgb(230, 230, 230);
  padding: 8px !important;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-box-shadow: -3px -3px 3px rgba(255, 255, 255, 0.1),
    -3px -3px 5px rgba(255, 255, 255, 0.9),
    3px 3px 3px rgba(255, 255, 255, 0.075), 3px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: -3px -3px 3px rgba(255, 255, 255, 0.1),
    -3px -3px 5px rgba(255, 255, 255, 0.9),
    3px 3px 3px rgba(255, 255, 255, 0.075), 3px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50% !important;
}
.slide-bottom-button:hover {
  background: rgb(233, 233, 233);
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.v-btn--icon.v-size--small {
  height: 15px !important;
  width: 15px !important;
}
.v-carousel > * .v-responsive__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
.v-carousel .portraits > * .v-responsive__content {
  background-color: rgb(248, 249, 252);
}

.v-progress-linear {
  -webkit-transition: 1s cubic-bezier(0.4, 0, 0.6, 1) !important;
  -o-transition: 1s cubic-bezier(0.4, 0, 0.6, 1) !important;
  transition: 1s cubic-bezier(0.4, 0, 0.6, 1) !important;
}
.v-progress-linear__determinate {
  background: var(--clr-accent) -webkit-gradient(linear, left top, right top, from(var(--clr-accent)), to(var(--clr-accent)));
  background: var(--clr-accent) -o-linear-gradient(left, var(--clr-accent), var(--clr-accent));
  background: var(--clr-accent)
    linear-gradient(to right, var(--clr-accent), var(--clr-accent));
  border-radius: 0px 20px 20px 0px !important;
}
.v-progress-linear__buffer {
  background-color: rgb(247, 248, 252) !important;
}
.v-carousel > * .theme--dark.v-image {
  height: 100% !important;
}
.v-window__container {
  height: 100% !important;
}
.v-carousel > * .v-responsive__sizer {
  padding-bottom: 0 !important;
}

.v-application--is-ltr
  .main-page
  .v-responsive__sizer
  ~ .v-responsive__content {
  display: flex;
  align-items: center;
}

.v-slide-group__wrapper {
  align-items: flex-end !important ;
}

.v-window-item.portraits .v-image__image {
  background-size: contain !important;
  width: 50%;
  left: 50%;
}
/*
.v-carousel > * .v-responsive__content {
  flex: 1 0 450px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}*/
/* FadeIn animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
