<template>
  <!-- eslint-disable -->
  <section
    id="header-wrapper"
    :class="{
      'large-screen-nav': $vuetify.breakpoint.mdAndUp,
      'small-screen-nav': $vuetify.breakpoint.smAndDown,
    }"
  >
    <!--Navbar para xl/lg/md/sm screens-->
    <v-app-bar flat class="hidden-sm-and-down">
      <v-container fluid>
        <v-row justify="center" align="center">
          <v-tooltip bottom color="var(--clr-accent-dark)">
            <template v-slot:activator="{ on }">
              <v-toolbar-title
                v-on="on"
                @click="goRoute('/', -1)"
                @keyup.enter="goRoute('/', -1)"
                :class="{
                  'logo-lg ml-12': $vuetify.breakpoint.lgAndUp,
                  'logo-md ml-8': $vuetify.breakpoint.mdAndDown,
                }"
              >
                <v-img
                  src="../assets/logo.png"
                  alt="A Divisão da Cor, soluções gráficas"
                  role="img"
                ></v-img>
              </v-toolbar-title>
            </template>
            <span>Voltar à página inicial</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-toolbar-items class="mr-2">
            <v-tabs hide-slider>
              <v-container v-for="tab in navbar" :key="tab.titulo" class="px-1">
                <v-tab
                  v-if="!tab.menu"
                  :class="{
                    active: tab.titulo === tabAtiva,
                    'nav-tab-lg': $vuetify.breakpoint.lgAndUp,
                    'nav-tab-md': $vuetify.breakpoint.md,
                  }"
                  @click="goRoute(tab.url, -1)"
                  :ripple="false"
                >
                  <p>
                    {{ tab.titulo }}
                  </p>
                </v-tab>
                <v-menu
                  v-if="tab.menu"
                  offset-y
                  open-on-hover
                  transition="scale-transition"
                  nudge-top="10"
                  nudge-left="4"
                >
                  <template v-slot:activator="{ on }">
                    <v-tab
                      v-on="on"
                      :class="{
                        active: tab.titulo === tabAtiva,
                        'nav-tab-lg': $vuetify.breakpoint.lgAndUp,
                        'nav-tab-md': $vuetify.breakpoint.mdAndDown,
                      }"
                      :ripple="false"
                    >
                      <p>{{ tab.titulo }}</p>
                      <svg-icon
                        v-if="tab.titulo === tabAtiva"
                        style="margin-bottom: 16px; margin-left: 7px"
                        name="arrow_down"
                        width="16"
                        height="auto"
                        color="var(--clr-accent)"
                      />
                      <svg-icon
                        v-else
                        style="margin-bottom: 16px; margin-left: 7px"
                        name="arrow_down"
                        width="16"
                        height="auto"
                        color="rgb(51, 58, 53)"
                      />
                    </v-tab>
                  </template>
                  <v-list rounded id="tab-menu">
                    <v-list-item
                      class="desktop-list"
                      v-for="menuLink in tab.menu"
                      :key="menuLink.opcao"
                      @click="goRoute(menuLink.url, menuLink.opt)"
                    >
                      <v-list-item-title class="text-wrap">
                        {{ menuLink.opcao }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-container>
            </v-tabs>
          </v-toolbar-items>
        </v-row>
      </v-container>
    </v-app-bar>
    <!--Navbar para xs/sm (mobile) screens-->
    <v-app-bar flat class="hidden-md-and-up">
      <v-toolbar-title
        @click="goRoute('/', -1)"
        :class="{
          'logo-sm ml-7': $vuetify.breakpoint.smOnly,
          'logo-xs ml-0': $vuetify.breakpoint.xsOnly,
        }"
      >
        <v-img
          src="../assets/logo.png"
          alt="A Divisão da Cor, soluções gráficas"
          role="img"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="fade-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            :ripple="false"
            class="default-button"
            :class="{
              'mr-7': $vuetify.breakpoint.smOnly,
              'mr-0': $vuetify.breakpoint.xsOnly,
            }"
          >
            <svg-icon
              name="menu"
              width="34"
              height="auto"
              v-bind:doublePath="true"
            />
          </v-btn>
        </template>
        <v-card>
          <v-app-bar flat style="height: 100px !important">
            <v-toolbar-title
              @click="goRoute('/', -1)"
              :class="{
                'logo-sm ml-7': $vuetify.breakpoint.smOnly,
                'logo-xs ml-0': $vuetify.breakpoint.xsOnly,
              }"
            >
              <v-img src="../assets/logo.png" alt="A Divisão da Cor"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :ripple="false"
              class="default-button"
              @click="dialog = false"
              :class="{
                'mr-7': $vuetify.breakpoint.smOnly,
                'mr-0': $vuetify.breakpoint.xsOnly,
              }"
            >
              <svg-icon
                name="close"
                width="34"
                height="auto"
                color="var(--clr-red-400)"
              />
            </v-btn>
          </v-app-bar>
          <v-list
            rounded
            color="rgba(0,0,0,0)"
            two-line
            :ripple="false"
            class="mt-12"
            :class="{
              'mx-7': $vuetify.breakpoint.smOnly,
              'mx-0': $vuetify.breakpoint.xsOnly,
            }"
          >
            <v-container v-for="tab in navbar" :key="tab.titulo">
              <v-list-item
                v-if="!tab.menu"
                :class="{ active: tab.titulo === tabAtiva }"
                @click="
                  goRoute(tab.url, -1);
                  dialog = false;
                  closeList();
                "
              >
                <v-list-item-title id="mobile-list">
                  <p class="d-inline">{{ tab.titulo }}</p>
                </v-list-item-title>
              </v-list-item>
              <v-list-group
                v-if="tab.menu"
                v-model="tab.active"
                :ripple="false"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      id="mobile-list"
                      class="mr-n12 ml-4"
                      :class="{ active: tab.titulo == tabAtiva }"
                    >
                      <p class="d-inline">{{ tab.titulo }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="menuLink in tab.menu"
                  :key="menuLink.opcao"
                  @click="
                    goRoute(menuLink.url, menuLink.opt);
                    dialog = false;
                    closeList();
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title
                      id="mobile-list-small"
                      class="text-wrap"
                      >{{ menuLink.opcao }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-container>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </section>
</template>

<script>
/* eslint-disable */
import SvgIcon from '@/components/SvgIcon.vue';
export default {
  name: 'Navbar',
  components: {
    SvgIcon,
  },
  data() {
    return {
      dialog: false,
      tabAtiva: 'Início',
      navbar: [
        {
          titulo: 'Início',
          url: '/',
        },
        {
          titulo: 'Quem Somos',
          url: '/QuemSomos',
        },
        {
          titulo: 'Empresa',
          active: false,
          menu: [
            {
              opcao: 'História',
              url: 'empresa',
              opt: 'Historia',
            },
            {
              opcao: 'Missão',
              url: 'empresa',
              opt: 'Missao',
            },
          ],
        },
        {
          titulo: 'Serviços',
          active: false,
          menu: [
            {
              opcao: 'CTP',
              url: 'servicos',
              opt: 'CTP', // 0
            },
            {
              opcao: 'Provas de Cor',
              url: 'servicos',
              opt: 'ProvasCor',
            },
            {
              opcao: 'Impressão Digital',
              url: 'servicos',
              opt: 'ImpressaoDigital',
            },
            {
              opcao: 'Packaging',
              url: 'servicos',
              opt: 'EmbalagensPersonalizadas',
            },
            {
              opcao: 'Design Gráfico',
              url: 'servicos',
              opt: 'DesignGrafico',
            },
            {
              opcao: 'Imposição Eletrónica',
              url: 'servicos',
              opt: 'ImposicaoEletronica',
            },
            {
              opcao: 'Publicidade',
              url: 'servicos',
              opt: 'Publicidade',
            },
            {
              opcao: 'Mesa de Corte Digital',
              url: 'servicos',
              opt: 'MesaCorteDigital',
            },
          ],
        },
        {
          titulo: 'Contactos',
          url: '/Contactos',
        },
      ],
    };
  },

  watch: {
    '$route.meta.tabAtiva': function(newValue) {
      this.tabAtiva = newValue;
    },
  },
  created: async function() {
    this.tabAtiva = this.$route.meta.tabAtiva;
  },
  methods: {
    closeList() {
      this.navbar.forEach((item) => {
        if (item.active) {
          item.active = false;
          return false;
        }
      });
    },
    goRoute: function(url, option) {
      if (option === -1) {
        this.$router.push(url).catch((error) => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
      } else {
        this.$router
          .push({
            name: url,
            params: {
              opt: option,
            },
          })
          .catch((error) => {
            if (error.name != 'NavigationDuplicated') {
              throw error;
            }
          });
      }
    },
  },
};
</script>

<style scoped>
#header-wrapper {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
}
.large-screen-nav {
  -ms-flex-preferred-size: 200px;
  flex-basis: 200px;
}
.small-screen-nav {
  -ms-flex-preferred-size: 100px;
  flex-basis: 100px;
}
.default-button {
  background: #efefef;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px !important;
}
.default-button:hover {
  -webkit-box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.default-button:active {
  -webkit-box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.15);
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.active {
  color: var(--clr-accent) !important;
  fill: var(--clr-accent) !important;
}
.logo-lg {
  width: 373px;
  cursor: pointer;
}
.logo-md {
  width: 321px;
  cursor: pointer;
}
.logo-sm {
  width: 269px;
  cursor: pointer;
}
.logo-xs {
  width: 235px;
  cursor: pointer;
}
.nav-tab-lg {
  margin-right: 16px;
  font-size: 1.2em !important;
  white-space: nowrap !important;
}
.nav-tab-md {
  margin-left: 3px;
  margin-right: 3px;
  font-size: 1em !important;
  white-space: nowrap !important;
}
#tab-menu {
  background-color: var(--clr-white) !important;
  padding-left: 10px;
  padding-right: 10px;
}

.container {
  padding: 0 !important;
  white-space: nowrap;
}
</style>
