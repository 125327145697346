<template>
  <!-- eslint-disable -->
  <section
    id="content-wrapper"
    :class="{
      'large-screen-content': $vuetify.breakpoint.mdAndUp,
      'small-screen-content': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-container class="py-0 hidden-sm-and-down" fluid>
      <v-row
        style="height:100%;"
        class="my-0 pa-0"
        :class="{
          'mx-12': $vuetify.breakpoint.lgAndUp,
          'mx-8': $vuetify.breakpoint.mdAndDown,
        }"
        role="main"
      >
        <v-col cols="12" md="6" lg="3" xl="2" class="py-0 px-4">
          <div class="contact-info-wrapper-single">
            <h1>
              Onde Estamos?
            </h1>
            <v-tooltip bottom color="var(--clr-accent-dark)">
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  class="d-flex"
                  target="_blank"
                  role="link"
                  aria-label="Link para Google Maps"
                  href="https://www.google.pt/maps/dir//R.+Nova+da+Esta%C3%A7%C3%A3o+164,+4700-234+Braga/@41.5495286,-8.4362815,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd24fede3df0ef71:0x1b14e8f24feade1a!2m2!1d-8.4362727!2d41.5495836!3e0"
                >
                  <svg-icon
                    name="location"
                    width="23"
                    height="auto"
                    v-bind:doublePath="true"
                  />
                  <div class="contact-text-wrapper">
                    <p>
                      Rua Nova da Estação, nº164
                      <br />
                      4700-234 Braga, Portugal
                    </p>
                    <p>GPS: N41°32'58'' W8°26'11''</p>
                  </div>
                </a>
              </template>
              <span>Obter Direções</span>
            </v-tooltip>
          </div>
          <v-divider class="divider"></v-divider>
          <div class="contact-info-wrapper-single">
            <h1 class="mt-n1">
              Contactos
            </h1>
            <v-tooltip bottom color="var(--clr-accent-dark)">
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  class="mb-3 d-flex link"
                  role="link"
                  href="tel:+351253283844"
                  aria-label="Link para App Mobile +351253283844"
                >
                  <svg-icon
                    name="phone"
                    width="25"
                    height="auto"
                    color="var(--clr-accent)"
                  />
                  <div class="contact-text-wrapper">
                    <p>
                      +351 253 283 844
                    </p>
                    <p style="font-size:0.8rem;">
                      (chamada para rede fixa nacional)
                    </p>
                  </div>
                </a>
              </template>
              <span>Contactar</span>
            </v-tooltip>
            <v-tooltip bottom color="var(--clr-accent-dark)">
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  class="mb-3 d-flex link"
                  role="link"
                  href="mailto: adivisaodacor@gmail.com"
                  aria-label="Link para App Email adivisaodacor@gmail.com"
                >
                  <svg-icon
                    name="email"
                    width="25"
                    height="auto"
                    v-bind:triplePath="true"
                  />
                  <div class="contact-text-wrapper">
                    <p>
                      adivisaodacor@gmail.com
                    </p>
                  </div>
                </a>
              </template>
              <span>Enviar Email</span>
            </v-tooltip>
          </div>
          <v-divider class="divider"></v-divider>
          <div class="contact-info-wrapper-single">
            <h1 style="margin-top: -1px;">
              Redes Sociais
            </h1>
            <v-tooltip bottom color="var(--clr-accent-dark)">
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  class="mb-3 d-flex link"
                  role="link"
                  href="https://www.facebook.com/adivisao.dacor"
                  aria-label="Link para Facebook"
                  target="blank"
                >
                  <svg-icon
                    name="facebook"
                    width="25"
                    height="auto"
                    v-bind:doublePath="true"
                  />
                  <div class="contact-text-wrapper">
                    <p>adivisao.dacor</p>
                  </div>
                </a>
              </template>
              <span>Ver página de Facebook</span>
            </v-tooltip>
            <v-tooltip bottom color="var(--clr-accent-dark)">
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  class="mb-3 d-flex link"
                  role="link"
                  href="https://www.instagram.com/adivisaodacor/"
                  aria-label="Link para Instagram"
                  target="blank"
                >
                  <svg-icon
                    name="instagram"
                    width="25"
                    height="auto"
                    v-bind:gradient="true"
                  />
                  <div class="contact-text-wrapper">
                    <p>adivisaodacor</p>
                  </div>
                </a>
              </template>
              <span>Ver página de Instagram</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="9" xl="10" class="py-0 d-flex">
          <v-progress-circular
            v-if="!this.map"
            id="loadingCircle"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
          <iframe
            @load="mapLoaded"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d746.4777000023255!2d-8.436281495022927!3d41.54952856121061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24fede3df0ef71%3A0x1b14e8f24feade1a!2sR.%20Nova%20da%20Esta%C3%A7%C3%A3o%20164%2C%204700-234%20Braga!5e0!3m2!1sen!2spt!4v1623850098732!5m2!1sen!2spt"
            :width="mapWidth"
            :height="mapHeight"
            style="border:0;"
            aria-hidden="false"
            title="Mapa"
            aria-label="Mapa"
            alt="Mapa"
            tabindex="0"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
    <!--Layout para sm xs screens-->
    <v-container class="py-0 hidden-md-and-up px-4" fluid>
      <v-row
        style="height:50%;"
        class="my-0 pa-0"
        :class="{
          'mx-7': $vuetify.breakpoint.smOnly,
          'mx-0': $vuetify.breakpoint.xsOnly,
        }"
        role="main"
      >
        <v-progress-circular
          v-if="!this.map"
          id="loadingCircle"
          :size="70"
          :width="7"
          indeterminate
        ></v-progress-circular>
        <iframe
          @load="mapLoaded"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d746.4777000023255!2d-8.436281495022927!3d41.54952856121061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24fede3df0ef71%3A0x1b14e8f24feade1a!2sR.%20Nova%20da%20Esta%C3%A7%C3%A3o%20164%2C%204700-234%20Braga!5e0!3m2!1sen!2spt!4v1623850098732!5m2!1sen!2spt"
          :width="mapWidth"
          :height="mapHeight"
          style="border:0;"
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </v-row>
      <v-row
        style="height:50%;"
        class="my-0 pa-0"
        :class="{
          'mx-7': $vuetify.breakpoint.smOnly,
          'mx-0': $vuetify.breakpoint.xsOnly,
        }"
      >
        <v-col cols="12" class="px-0 pb-0 mt-2">
          <div class="contact-info-wrapper-single">
            <h1 class="mb-0">
              Onde Estamos?
            </h1>
            <a
              target="_blank"
              class="d-flex mobile-link"
              role="link"
              aria-label="Link para Google Maps"
              href="https://www.google.pt/maps/dir//R.+Nova+da+Esta%C3%A7%C3%A3o+164,+4700-234+Braga/@41.5495286,-8.4362815,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd24fede3df0ef71:0x1b14e8f24feade1a!2m2!1d-8.4362727!2d41.5495836!3e0"
            >
              <svg-icon
                name="location"
                width="20"
                height="auto"
                v-bind:doublePath="true"
              />
              <div class="contact-text-wrapper">
                <p>
                  Rua Nova da Estação, nº164 <br />
                  4700-234 Braga, Portugal
                </p>
                <p>GPS: N41°32'58'' W8°26'11''</p>
              </div>
            </a>
          </div>
          <v-divider class="divider"></v-divider>
          <div class="contact-info-wrapper-single">
            <h1 class="mb-0 mt-n1">
              Contactos
            </h1>
            <a
              class="mb-3 d-flex mobile-link"
              href="tel:+351253283844"
              role="link"
              aria-label="Link para App Mobile +351253283844"
            >
              <svg-icon
                name="phone"
                width="20"
                height="auto"
                color="var(--clr-accent)"
              />
              <div class="contact-text-wrapper">
                <p>
                  +351 253 283 844
                </p>
                <p style="font-size:0.8rem;">
                  (chamada para rede fixa nacional)
                </p>
              </div>
            </a>
            <a
              class="d-flex mobile-link"
              href="mailto: adivisaodacor@gmail.com"
              role="link"
              aria-label="Link para App Email adivisaodacor@gmail.com "
            >
              <svg-icon
                name="email"
                width="20"
                height="auto"
                color="var(--clr-accent)"
                v-bind:triplePath="true"
              />
              <div class="contact-text-wrapper">
                <p>
                  adivisaodacor@gmail.com
                </p>
              </div>
            </a>
          </div>
          <v-divider class="divider"></v-divider>
          <div class="contact-info-wrapper-single">
            <h1 style="margin-top: -1px;" class="mb-0">
              Redes Sociais
            </h1>
            <a
              class="mb-3 d-flex mobile-link"
              role="link"
              href="https://www.facebook.com/adivisao.dacor"
              aria-label="Link para Facebook"
              target="blank"
            >
              <svg-icon
                name="facebook"
                width="20"
                height="auto"
                v-bind:doublePath="true"
              />
              <div class="contact-text-wrapper">
                <p>adivisao.dacor</p>
              </div>
            </a>
            <a
              class="mb-3 d-flex mobile-link"
              href="https://www.instagram.com/adivisaodacor/"
              aria-label="Link para Instagram"
              target="blank"
            >
              <svg-icon
                name="instagram2"
                width="20"
                height="auto"
                v-bind:gradient="true"
              />
              <div class="contact-text-wrapper">
                <p>adivisaodacor</p>
              </div>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
/* eslint-disable */
import SvgIcon from '@/components/SvgIcon.vue';
export default {
  name: 'Contactos',
  metaInfo: {
    title: 'Contactos',
    meta: [
      {
        name: 'description',
        content: 'Concretizamos os seus projetos gráficos. Consulte-nos.',
      },
      {
        name: 'keywords',
        content:
          'Soluções Gráficas, Braga, Guimarães, Famalicão, Barcelos, Minho',
      },
    ],
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      map: false,
      mapWidth: '0',
      mapHeight: '0',
    };
  },
  methods: {
    mapLoaded: function() {
      this.map = true;
      this.mapWidth = '100%';
      this.mapHeight = '100%';
    },
  },
};
</script>
<style scoped>
#content-wrapper {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: flex;
  align-content: stretch;
}
.large-screen-content {
  -ms-flex-preferred-size: 500px;
  flex-basis: 500px;
}
.small-screen-content {
  -ms-flex-preferred-size: 800px;
  flex-basis: 800px;
}
.contact-info-wrapper-single {
  color: var(--clr-grey-800) !important;
}
.contact-info-wrapper-single > h1 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 2rem;
}
.link {
  align-items: center;
}
.mobile-link {
  width: 300px;
  align-items: center;
}
.contact-info-wrapper-single > * p {
  font-size: 1rem;
  margin-bottom: 0 !important;
}
.contact-text-wrapper {
  padding: 0 15px;
  padding-top: 0px;
}
.divider {
  border: 0.5px solid var(--clr-accent-light) !important;
  border-radius: 20px !important;
  margin: 20px 15px;
}
#loadingCircle {
  margin: 0 auto;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: var(--clr-accent);
}
.col-xl-2 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}
.col-xl-10 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}
@media screen and (min-width: 2501px) {
  .contact-info-wrapper-single > * p {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 2500px) and (min-width: 2001px) {
  .contact-info-wrapper-single > * p {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 2000px) and (min-width: 1401px) {
  .contact-text-wrapper {
    margin-top: 0 !important;
  }
  .contact-info-wrapper-single > a {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 1264px) and (min-width: 961px) {
  .contact-info-wrapper-single > a {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 600px) {
  .contact-info-wrapper-single > h1 {
    font-size: 1.6rem;
  }
  .contact-info-wrapper-single > * p {
    font-size: 0.9rem;
  }
}
</style>
