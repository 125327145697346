<template>
  <section id="content-wrapper">
    <v-carousel
      style="height: unset !important;"
      v-model="slide"
      hide-delimiters
      :show-arrows="false"
    >
      <v-carousel-item
        style="height:100%;"
        v-for="page in pages"
        :key="page.titulo"
        :src="page.background"
        :class="{
          portraits: $vuetify.breakpoint.lgAndUp,
        }"
      >
        <div id="slider-content">
          <h1 class="mt-3">{{ page.titulo }}</h1>
          <p>{{ page.subtitulo }}</p>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex" id="slide-control">
      <div v-for="(page, i) in pages" :key="i">
        <v-btn
          @click="slide = i"
          icon
          small
          class="slide-bottom-button mx-3"
          :ripple="false"
          :class="{ active_slide: slide === i }"
        >
        </v-btn>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'QuemSomos',
  metaInfo: {
    title: 'QuemSomos',
    meta: [
      {
        name: 'description',
        content: 'Meter Descrição',
      },
      {
        name: 'keywords',
        content:
          'Soluções Gráficas, Braga, Guimarães, Famalicão, Barcelos, Minho',
      },
    ],
  },
  data() {
    return {
      pages: [
        {
          titulo: 'Luís',
          background: require('../assets/LUIS.jpg'),
          subtitulo:
            'O mais experiente em artes gráficas, possuidor de um olho clínico ao qual pouca coisa escapa. Ao seu perfecionismo acrescenta uma capacidade criativa adequada ao produto final desejado. Naturalmente bem disposto, não morde.',
        },
        {
          titulo: 'Joaquim',
          background: require('../assets/JOAQUIM.jpg'),
          subtitulo:
            'Empreendedor e dinâmico, sempre disponível para o cliente. Conhecedor do mercado e das tendências, com elevada experiência em pré-impressão e impressão, é a pessoa ideal para conduzir o trabalho desde a cotação até a entrega. Uma simpatia genuína, charmoso desde pequeno.',
        },
        {
          titulo: 'Rui',
          background: require('../assets/RUI.jpg'),
          subtitulo:
            'Um profissional dedicado à produção gráfica, onde domina a imposição electrónica como poucos. Organizado e metódico, consegue sempre cumprir os prazos estabelecidos. Debaixo do seu corpo gigante, está uma pessoa bondosa e sensível, que não faz mal a uma mosca.',
        },
        {
          titulo: 'Bruno',
          background: require('../assets/BRUNO.jpg'),
          subtitulo:
            'O nosso “puto” tem um notável bom gosto e uma grande tendência para o desenvolvimento de packaging e impressão digital. Sempre a par das e-tendências. Ao seu conhecimento gráfico alia um know-how fotográfico profissional, do qual podem usufruir. De todos é o mais bonito e, apesar das tatuagens, não é drogado.',
        },
      ],
      slide: null,
    };
  },

  methods: {},
};
</script>
<style scoped>
#content-wrapper {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 450px;
  flex: 1 0 450px;
  display: flex;
  align-content: stretch;
  animation: fadeIn ease 1.5s;
}
.active_slide {
  background: var(--clr-accent);
  -webkit-box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(19, 19, 2, 0.5);
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(19, 19, 2, 0.5);
}
#slider-content {
  width: 40%;
  margin-left: 8%;
  margin-top: 188px;
  color: var(--clr-grey-800);
}
#slider-content > h1 {
  font-size: 2rem;
}
#slide-control {
  position: absolute;
  bottom: 65px;
  margin-left: -60px;
  left: 50%;
}

@media screen and (min-width: 2501px) {
  #slider-content > h1 {
    font-size: 3.5rem;
  }
  #slider-content > svg {
    width: 260px;
    height: 260px;
  }
  #slider-content > p {
    font-size: 2rem;
    text-align: left;
  }
}
@media screen and (max-width: 1400px) and (min-width: 901px) {
  #slider-content {
    width: 40%;
  }
}
@media screen and (max-width: 1263px) {
  #slider-content {
    color: var(--clr-white);
  }
}
@media screen and (max-width: 900px) {
  #slider-content {
    width: 60%;
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  #slider-content {
    padding-left: 44px;
  }
}
@media screen and (max-width: 600px) {
  #slider-content {
    width: 100%;
    margin-left: 0;
    padding: 0 16px;
    margin-top: 128px;
  }
  #slider-content > h1 {
    font-size: 1.6rem;
  }
  #slider-content > svg {
    width: 120px;
    height: 120px;
  }
  #slider-content > p {
    font-size: 0.9rem;
    text-align: left;
  }
}
</style>
