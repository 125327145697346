/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import vuetify from './plugins/vuetify';

Vue.use(
  VueGtag,
  {
    // config: { id: "UA-1234567-1" }
    config: { id: '264940063' },
  },
  router
);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
