var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{
    'large-screen-nav': _vm.$vuetify.breakpoint.mdAndUp,
    'small-screen-nav': _vm.$vuetify.breakpoint.smAndDown,
  },attrs:{"id":"header-wrapper"}},[_c('v-app-bar',{staticClass:"hidden-sm-and-down",attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"var(--clr-accent-dark)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-toolbar-title',_vm._g({class:{
                'logo-lg ml-12': _vm.$vuetify.breakpoint.lgAndUp,
                'logo-md ml-8': _vm.$vuetify.breakpoint.mdAndDown,
              },on:{"click":function($event){return _vm.goRoute('/', -1)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goRoute('/', -1)}}},on),[_c('v-img',{attrs:{"src":require("../assets/logo.png"),"alt":"A Divisão da Cor, soluções gráficas","role":"img"}})],1)]}}])},[_c('span',[_vm._v("Voltar à página inicial")])]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"mr-2"},[_c('v-tabs',{attrs:{"hide-slider":""}},_vm._l((_vm.navbar),function(tab){return _c('v-container',{key:tab.titulo,staticClass:"px-1"},[(!tab.menu)?_c('v-tab',{class:{
                  active: tab.titulo === _vm.tabAtiva,
                  'nav-tab-lg': _vm.$vuetify.breakpoint.lgAndUp,
                  'nav-tab-md': _vm.$vuetify.breakpoint.md,
                },attrs:{"ripple":false},on:{"click":function($event){return _vm.goRoute(tab.url, -1)}}},[_c('p',[_vm._v(" "+_vm._s(tab.titulo)+" ")])]):_vm._e(),(tab.menu)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","transition":"scale-transition","nudge-top":"10","nudge-left":"4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-tab',_vm._g({class:{
                      active: tab.titulo === _vm.tabAtiva,
                      'nav-tab-lg': _vm.$vuetify.breakpoint.lgAndUp,
                      'nav-tab-md': _vm.$vuetify.breakpoint.mdAndDown,
                    },attrs:{"ripple":false}},on),[_c('p',[_vm._v(_vm._s(tab.titulo))]),(tab.titulo === _vm.tabAtiva)?_c('svg-icon',{staticStyle:{"margin-bottom":"16px","margin-left":"7px"},attrs:{"name":"arrow_down","width":"16","height":"auto","color":"var(--clr-accent)"}}):_c('svg-icon',{staticStyle:{"margin-bottom":"16px","margin-left":"7px"},attrs:{"name":"arrow_down","width":"16","height":"auto","color":"rgb(51, 58, 53)"}})],1)]}}],null,true)},[_c('v-list',{attrs:{"rounded":"","id":"tab-menu"}},_vm._l((tab.menu),function(menuLink){return _c('v-list-item',{key:menuLink.opcao,staticClass:"desktop-list",on:{"click":function($event){return _vm.goRoute(menuLink.url, menuLink.opt)}}},[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(menuLink.opcao)+" ")])],1)}),1)],1):_vm._e()],1)}),1)],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-md-and-up",attrs:{"flat":""}},[_c('v-toolbar-title',{class:{
        'logo-sm ml-7': _vm.$vuetify.breakpoint.smOnly,
        'logo-xs ml-0': _vm.$vuetify.breakpoint.xsOnly,
      },on:{"click":function($event){return _vm.goRoute('/', -1)}}},[_c('v-img',{attrs:{"src":require("../assets/logo.png"),"alt":"A Divisão da Cor, soluções gráficas","role":"img"}})],1),_c('v-spacer'),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"default-button",class:{
            'mr-7': _vm.$vuetify.breakpoint.smOnly,
            'mr-0': _vm.$vuetify.breakpoint.xsOnly,
          },attrs:{"icon":"","ripple":false}},on),[_c('svg-icon',{attrs:{"name":"menu","width":"34","height":"auto","doublePath":true}})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-app-bar',{staticStyle:{"height":"100px !important"},attrs:{"flat":""}},[_c('v-toolbar-title',{class:{
              'logo-sm ml-7': _vm.$vuetify.breakpoint.smOnly,
              'logo-xs ml-0': _vm.$vuetify.breakpoint.xsOnly,
            },on:{"click":function($event){return _vm.goRoute('/', -1)}}},[_c('v-img',{attrs:{"src":require("../assets/logo.png"),"alt":"A Divisão da Cor"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"default-button",class:{
              'mr-7': _vm.$vuetify.breakpoint.smOnly,
              'mr-0': _vm.$vuetify.breakpoint.xsOnly,
            },attrs:{"icon":"","ripple":false},on:{"click":function($event){_vm.dialog = false}}},[_c('svg-icon',{attrs:{"name":"close","width":"34","height":"auto","color":"var(--clr-red-400)"}})],1)],1),_c('v-list',{staticClass:"mt-12",class:{
            'mx-7': _vm.$vuetify.breakpoint.smOnly,
            'mx-0': _vm.$vuetify.breakpoint.xsOnly,
          },attrs:{"rounded":"","color":"rgba(0,0,0,0)","two-line":"","ripple":false}},_vm._l((_vm.navbar),function(tab){return _c('v-container',{key:tab.titulo},[(!tab.menu)?_c('v-list-item',{class:{ active: tab.titulo === _vm.tabAtiva },on:{"click":function($event){_vm.goRoute(tab.url, -1);
                _vm.dialog = false;
                _vm.closeList();}}},[_c('v-list-item-title',{attrs:{"id":"mobile-list"}},[_c('p',{staticClass:"d-inline"},[_vm._v(_vm._s(tab.titulo))])])],1):_vm._e(),(tab.menu)?_c('v-list-group',{attrs:{"ripple":false,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-n12 ml-4",class:{ active: tab.titulo == _vm.tabAtiva },attrs:{"id":"mobile-list"}},[_c('p',{staticClass:"d-inline"},[_vm._v(_vm._s(tab.titulo))])])],1)]},proxy:true}],null,true),model:{value:(tab.active),callback:function ($$v) {_vm.$set(tab, "active", $$v)},expression:"tab.active"}},_vm._l((tab.menu),function(menuLink){return _c('v-list-item',{key:menuLink.opcao,on:{"click":function($event){_vm.goRoute(menuLink.url, menuLink.opt);
                  _vm.dialog = false;
                  _vm.closeList();}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap",attrs:{"id":"mobile-list-small"}},[_vm._v(_vm._s(menuLink.opcao))])],1)],1)}),1):_vm._e()],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }