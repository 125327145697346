<template>
  <section id="content-wrapper">
    <v-carousel
      style="height: unset !important;"
      v-model="slide"
      hide-delimiters
      :show-arrows="false"
    >
      <v-carousel-item
        style="height:100%;"
        v-for="page in pages"
        :key="page.titulo"
        :src="page.background"
      >
        <div id="slider-content">
          <h1 class="mt-3">{{ page.titulo }}</h1>
          <p>{{ page.subtitulo }}</p>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex" id="slide-control">
      <div v-for="(page, i) in pages" :key="i">
        <v-btn
          @click="slide = i"
          icon
          small
          class="slide-bottom-button mx-3"
          :ripple="false"
          :class="{ active_slide: slide === i }"
        >
        </v-btn>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Empresa',
  metaInfo: {
    title: 'Descrição',
    meta: [
      {
        name: 'description',
        content: 'Meter Descrição',
      },
      {
        name: 'keywords',
        content:
          'Soluções Gráficas, Braga, Guimarães, Famalicão, Barcelos, Minho',
      },
    ],
  },
  data() {
    return {
      pages: [
        {
          titulo: 'A nossa história...',
          background: require('../assets/DSC07872.jpg'),
          subtitulo:
            'Todos nós começamos a nossa vida profissional em artes gráficas, o que nos permite dizer que somos gráficos desde sempre. Juntamo-nos em volta do projeto A Divisão da Cor em 2010, para servir todas as empresas do sector gráfico. Passados esses anos, numas instalações novas e independentes, estamos num momento de “viragem”, no qual nos propomos servir ainda mais o sector gráfico — e não só — através de uma oferta mais completa.',
        },
        {
          titulo: 'A nossa missão...',
          background: require('../assets/DSC07883.jpg'),
          subtitulo:
            'Desde 2021, estamos disponíveis para todo o tipo de clientes. Apostamos num serviço personalizado, de acordo com o que o cliente final necessita, satisfazendo com igual entrega grandes e pequenas quantidades. O nosso forte? Tudo o que for gráfico. Torne os desejos para a sua empresa uma realidade: consulte-nos!',
        },
      ],
      slide: null,
    };
  },

  watch: {
    $route(to) {
      switch (to.params.opt) {
        case 'Historia':
          this.slide = 0;
          break;
        case 'Missao':
          this.slide = 1;
          break;
      }
    },
    slide(newValue, oldValue) {
      let value;
      if (oldValue && value) value = oldValue;
      switch (newValue) {
        case 0:
          newValue = 'Historia';
          break;
        case 1:
          newValue = 'Missao';
          break;
      }
      this.$router
        .push({
          name: 'empresa',
          params: {
            opt: newValue,
          },
        })
        .catch((error) => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
  },
  created: function() {
    switch (this.$route.params.opt) {
      case 'Historia':
        this.slide = 0;
        break;
      case 'Missao':
        this.slide = 1;
        break;
    }
  },
  methods: {
    check: function(data) {
      let value;
      if (data && value) value = data;
    },
  },
};
</script>
<style scoped>
#content-wrapper {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 450px;
  flex: 1 0 450px;
  display: flex;
  align-content: stretch;
  animation: fadeIn ease 1.5s;
}
.active_slide {
  background: var(--clr-accent);
  -webkit-box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(19, 19, 2, 0.5);
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(19, 19, 2, 0.5);
}
#slider-content {
  width: 40%;
  margin-left: 8%;
  margin-top: 188px;
}
#slider-content > h1 {
  font-size: 2rem;
}
#slide-control {
  position: absolute;
  bottom: 65px;
  margin-left: -60px;
  left: 50%;
}
@media screen and (min-width: 2501px) {
  #slider-content > h1 {
    font-size: 3.5rem;
  }
  #slider-content > svg {
    width: 260px;
    height: 260px;
  }
  #slider-content > p {
    font-size: 2rem;
    text-align: left;
  }
}
@media screen and (max-width: 1400px) and (min-width: 901px) {
  #slider-content {
    width: 40%;
  }
}
@media screen and (max-width: 900px) {
  #slider-content {
    width: 60%;
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  #slider-content {
    padding-left: 44px;
  }
}
@media screen and (max-width: 600px) {
  #slider-content {
    width: 100%;
    margin-left: 0;
    padding: 0 16px;
    margin-top: 128px;
  }
  #slider-content > h1 {
    font-size: 1.6rem;
  }
  #slider-content > svg {
    width: 120px;
    height: 120px;
  }
  #slider-content > p {
    font-size: 0.9rem;
    text-align: left;
  }
}
</style>
