<template>
  <section id="content-wrapper">
    <v-carousel
      style="height: unset !important;"
      v-model="slide"
      hide-delimiters
      :show-arrows="false"
    >
      <v-carousel-item
        style="height:100%;"
        v-for="page in pages"
        :key="page.titulo"
        :src="page.background"
      >
        <div id="slider-content">
          <svg-icon
            v-if="page.icon === 'imposicaoDigital'"
            :name="page.icon"
            width="180"
            height="auto"
            color="rgb(255, 255, 255)"
            ninethPath="true"
          />
          <svg-icon
            v-else-if="page.icon === 'publicidade'"
            :name="page.icon"
            width="180"
            height="auto"
            color="rgb(255, 255, 255)"
            doublePath="true"
          />
          <svg-icon
            v-else
            :name="page.icon"
            width="180"
            height="auto"
            color="rgb(255, 255, 255)"
          />
          <h1 class="mt-3">{{ page.titulo }}</h1>
          <p>{{ page.subtitulo }}</p>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex" id="slide-control">
      <div v-for="(page, i) in pages" :key="i">
        <v-btn
          @click="slide = i"
          icon
          small
          class="slide-bottom-button mx-3"
          :ripple="false"
          :class="{ active_slide: slide === i }"
        >
        </v-btn>
      </div>
    </div>
  </section>
</template>
<script>
import SvgIcon from '@/components/SvgIcon.vue';

export default {
  name: 'Servicos',
  metaInfo: {
    title:
      'CTP | Provas de Cor | Impressão Digital | Packaging | Design Gráfico | Imposição Eletrónica | Publicidade | Mesa de Corte Digital',
    titleTemplate: null,
    meta: [
      {
        name: 'description',
        content:
          'A Divisão da Cor abrange a área da consultoria/artes gráficas, impressão digital/de rígidos, e packaging personalizado',
      },
      {
        name: 'keywords',
        content:
          'CTP, Provas de Cor, Impressão Digital, Embalagens Personalizadas, Design Gráfico',
      },
    ],
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      pages: [
        {
          titulo: 'CTP',
          background: require('../assets/DSC07863.jpg'),
          icon: 'ctp',
          subtitulo: '',
        },
        {
          titulo: 'Provas de Cor Certificadas',
          background: require('../assets/DSC07850.jpg'),
          icon: 'provCor',
          subtitulo: '',
        },
        {
          titulo: 'Impressão Digital',
          background: require('../assets/DSC07876.jpg'),
          icon: 'imprDigital',
          subtitulo: '',
        },
        {
          titulo: 'Packaging',
          background: require('../assets/PACKAGING.jpg'),
          icon: 'emblPersonzd',
          subtitulo: '',
        },
        {
          titulo: 'Design Gráfico',
          background: require('../assets/DESIGNGRAFICO.jpg'),
          icon: 'desGrafico',
          subtitulo: '',
        },
        {
          titulo: 'Imposição Eletrónica',
          background: require('../assets/IMPOSICAOELETRONICA.jpg'),
          icon: 'imposicaoDigital',
          subtitulo: '',
        },
        {
          titulo: 'Publicidade',
          background: require('../assets/PUBLICIDADE.jpg'),
          icon: 'publicidade',
          subtitulo: '',
        },
        {
          titulo: 'Mesa de Corte Digital',
          background: require('../assets/CORTE.jpg'),
          icon: 'corte',
          subtitulo: '',
        },
      ],
      slide: null,
    };
  },

  watch: {
    $route(to) {
      switch (to.params.opt) {
        case 'CTP':
          this.slide = 0;
          break;
        case 'ProvasCor':
          this.slide = 1;
          break;
        case 'ImpressaoDigital':
          this.slide = 2;
          break;
        case 'EmbalagensPersonalizadas':
          this.slide = 3;
          break;
        case 'DesignGrafico':
          this.slide = 4;
          break;
        case 'ImposicaoEletronica':
          this.slide = 5;
          break;
        case 'Publicidade':
          this.slide = 6;
          break;
        case 'MesaCorteDigital':
          this.slide = 7;
          break;
      }
    },
    slide(newValue, oldValue) {
      let value;
      if (oldValue && value) value = oldValue;
      switch (newValue) {
        case 0:
          newValue = 'CTP';
          break;
        case 1:
          newValue = 'ProvasCor';
          break;
        case 2:
          newValue = 'ImpressaoDigital';
          break;
        case 3:
          newValue = 'EmbalagensPersonalizadas';
          break;
        case 4:
          newValue = 'DesignGrafico';
          break;
        case 5:
          newValue = 'ImposicaoEletronica';
          break;
        case 6:
          newValue = 'Publicidade';
          break;
        case 7:
          newValue = 'MesaCorteDigital';
          break;
      }
      this.$router
        .push({
          name: 'servicos',
          params: {
            opt: newValue,
          },
        })
        .catch((error) => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
  },
  created: function() {
    switch (this.$route.params.opt) {
      case 'CTP':
        this.slide = 0;
        break;
      case 'ProvasCor':
        this.slide = 1;
        break;
      case 'ImpressaoDigital':
        this.slide = 2;
        break;
      case 'EmbalagensPersonalizadas':
        this.slide = 3;
        break;
      case 'DesignGrafico':
        this.slide = 4;
        break;
      case 'ImposicaoEletronica':
        this.slide = 5;
        break;
      case 'Publicidade':
        this.slide = 6;
        break;

      case 'MesaCorteDigital':
        this.slide = 7;
        break;
    }
  },
  methods: {
    check: function(data) {
      let value;
      if (data && value) value = data;
    },
  },
};
</script>
<style scoped>
#content-wrapper {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 450px;
  flex: 1 0 450px;
  display: flex;
  align-content: stretch;
  animation: fadeIn ease 1.5s;
}
.active_slide {
  background: var(--clr-accent);
  -webkit-box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(19, 19, 2, 0.5);
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(19, 19, 2, 0.5);
}
#slider-content {
  width: 40%;
  margin-left: 8%;
}

#slider-content > h1 {
  font-size: 2rem;
}
#slide-control {
  position: absolute;
  bottom: 65px;
  margin-left: -100px;
  left: 50%;
}
@media screen and (min-width: 2501px) {
  #slider-content > h1 {
    font-size: 3.5rem;
  }
  #slider-content > svg {
    width: 260px;
    height: 260px;
  }
  #slider-content > p {
    font-size: 2rem;
    text-align: left;
  }
}
@media screen and (max-width: 1400px) and (min-width: 901px) {
  #slider-content {
    width: 40%;
  }
}
@media screen and (max-width: 900px) {
  #slider-content {
    width: 60%;
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 900px) and (min-width: 601px) {
  #slider-content {
    padding-left: 44px;
  }
}
@media screen and (max-width: 600px) {
  #slider-content {
    width: 100%;
    margin-left: 0;
    padding: 0 16px;
  }
  #slider-content > h1 {
    font-size: 1.6rem;
  }
  #slider-content > svg {
    width: 120px;
    height: 120px;
  }
  #slider-content > p {
    font-size: 0.9rem;
    text-align: left;
  }
}
</style>
