import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Inicio.vue'),
      meta: { tabAtiva: 'Início' },
    },
    {
      path: '/QuemSomos',
      name: 'quemSomos',
      component: () => import('@/views/QuemSomos.vue'),
      meta: { tabAtiva: 'Quem Somos' },
    },
    {
      path: '/Empresa/:opt',
      name: 'empresa',
      component: () => import('@/views/Empresa.vue'),
      meta: { tabAtiva: 'Empresa' },
    },

    {
      path: '/Servicos/:opt',
      name: 'servicos',
      component: () => import('@/views/Servicos.vue'),
      meta: { tabAtiva: 'Serviços' },
    },
    {
      path: '/Contactos',
      name: 'contactos',
      component: () => import('@/views/Contactos.vue'),
      meta: { tabAtiva: 'Contactos' },
    },
    {
      path: '*',
      component: () => import('@/views/Inicio.vue'),
      meta: { tabAtiva: 'Início' },
    },
  ],
});
